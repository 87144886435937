import React, { useState } from 'react'
import UnderscoreAnimation from '../../UnderscoreAnimation/UnderscoreAnimation'
import './DefaultButton.css'

const DefaultButton = ({className, text = 'button', onClick, ...props }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <button 
      className={`DefaultButton ${className}` }
      onClick={onClick} 
      onMouseOver={() => setIsActive(true)} 
      onMouseOut={() => setIsActive(false)} 
      {...props}
    >
      <span>{text}</span>
      <UnderscoreAnimation isActive={isActive} />
    </button>
  )
}

export default DefaultButton