import React from 'react'
import config from '../../config.json'
import es from '../../I18n/es.json'
import MoveButton from '../Buttons/MoveButton/MoveButton'
import './SocialNetworks.css'
import instagramLogo from './insta_icon.png'
// import linkedInLogo from './lin_icon.png'
import youtubeLogo from './yt_icon.png'

// const instagramLogo = `${config.common.aws_s3}${config.links.social_networks.instagram_logo}`
// const linkedInLogo = `${config.common.aws_s3}${config.links.social_networks.linkedin_logo}`

const SocialNetworks = ({ align }) => {
  return (
    <ul className={`Social_networks ${align === 'left' ? 'Social_networks_align_left' : ''}`}>
      <li className='Social_networks_icon_container'>
        <a title='Ir a Instagram' href={config.links.social_networks.instagram} target='_blank' rel='noopener noreferrer'>
          <MoveButton animation={2}>
            <img className='Social_networks_icon' src={instagramLogo} alt={es.social_network_instagram_alt} />
          </MoveButton>
        </a>
      </li>
      {/* <li className='Social_networks_icon_container'>
        <a title='Ir a LinkedIn' href={config.links.social_networks.instagram} target='_blank' rel='noopener noreferrer'>
          <MoveButton animation={1}>
            <img className='Social_networks_icon' src={linkedInLogo} alt={es.social_network_instagram_alt} />
          </MoveButton>
        </a>
      </li> */}
      <li className='Social_networks_icon_container'>
        <a title='Ir a Youtube' href={config.links.social_networks.youtube} target='_blank' rel='noopener noreferrer'>
          <MoveButton animation={1}>
            <img className='Social_networks_icon' src={youtubeLogo} alt={es.social_network_youtube_alt} />
          </MoveButton>
        </a>
      </li>
    </ul>
  )
}

export default SocialNetworks