import React from 'react'
import Footer from '../../components/Footer/Footer'
import Layout from '../../layout/Layout'
import es from '../../I18n/es.json'
import './Exhibition.css'
import exp1 from './exp1.jpg'
import exp2 from './exp2.jpg'
import exp3 from './exp3.jpg'
import exp4 from './exp4.jpg'
import exp5 from './exp5.jpg'
import exp6 from './exp6.jpg'
import exp7 from './exp7.jpg'
import exp8 from './exp8.jpg'


const ExhibitionTime = () => {
  return (
  <div className='Exhibitions_time-container'>
      <div>
        <span className='Exhibitions_title'>2021</span>  
        <span className='Exhibitions_text'>Colectiva. “18 granadas y una mirada” Ateneo, Sevilla.</span>  
      </div>
      <div>
        <span className='Exhibitions_title'>2019</span>  
        <span className='Exhibitions_text'>Individual. “Proemio” Casa de la Cultura, Alcalá de Guadaíra, Sevilla.</span>  
      </div>
      <div>
        <span className='Exhibitions_title'>2019</span>         
        <span className='Exhibitions_text'>Colectiva. “Artistas contra el hambre” Fundación Caja Sol” Sevilla</span>         
      </div>
      <div>
        <span className='Exhibitions_title'>2018</span>
        <span className='Exhibitions_text'>Colectiva. “Encuentro” Casa de la Provincia, Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>2018</span>
        <span className='Exhibitions_text'>Individual. Centro kinema, Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>2017</span>
        <span className='Exhibitions_text'>Individual. Ateneo de Mairena del Aljarafe, Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>2017</span>
        <span className='Exhibitions_text'>Art Fair Málaga. Feria Internacional de Arte Contemporáneo</span>
      </div>
      <div>
        <span className='Exhibitions_title'>2002</span>
        <span className='Exhibitions_text'>Colectiva. “Generación 88” Galería Mecenas, Málaga</span>
      </div>
      <div>
        <span className='Exhibitions_title'>2001</span>
        <span className='Exhibitions_text'>Colectiva. “Generación 88” Casa de la Cultura, Alcalá de Guadaira, Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>1999</span> 
        <span className='Exhibitions_text'>Sala de exposiciones “Antiguo Hospital de San Gerónimo”, Marchena, Sevilla</span> 
      </div>
      <div>
        <span className='Exhibitions_title'>1995</span>
        <span className='Exhibitions_text'>Sala de exposiciones del Monte de Piedad Marchena, Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>1991</span>
        <span className='Exhibitions_text'>Colectiva “Arte por Triana”. Galería, Centro Cultural de los Ejércitos. Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>1988</span>
        <span className='Exhibitions_text'>Casa de la cultura Alcalá de Guadaira, Sevilla</span>
      </div>
      <div>
        <span className='Exhibitions_title'>1986</span>  
        <span className='Exhibitions_text'>Colectiva, “Homenaje a D. Manuel Ferrand”. Galería Montserrat. Sevilla</span>  
      </div>
  </div>
  )
}

const Exhibition = () => {
  return (
    <Layout>
      <div className='Exhibitions'>
        <div className='Exhibitions_main'>
          
          <div className='Exhibitions_img-container'>
            <img className='Exhibitions_main-img kinema' src={exp5} />
            <img className='Exhibitions_main-img kinema' src={exp1} />
            <img className='Exhibitions_main-img kinema' src={exp3} />
            <img className='Exhibitions_main-img kinema' src={exp7} />
          </div>
          <div className='Exhibitions_img-container'>
            <img className='Exhibitions_main-img kinema' src={exp4} />
            <img className='Exhibitions_main-img kinema' src={exp6} />
            <img className='Exhibitions_main-img kinema' src={exp2} />
            <img className='Exhibitions_main-img kinema' src={exp8} />
          </div>


          <ExhibitionTime />
        </div>
        <Footer light />
      </div>
    </Layout>
  )
}

export default Exhibition