import React from 'react'
import es from '../../I18n/es.json'
import Serie from '../../components/Serie/Serie'
import './Header.css'

const Header = () => {
  return (
    <div className='Header'>
      <div className='Header_main'>
        <div className='Header_title'>
          <p className='Header_title_name'>Ardea</p>
          <p className='Header_title_name'>Cinerea</p>
        <h2 className='Header_artist'>{es.home_artist}</h2>
        </div>
        <h2 className='Header_subtitle'>{es.home_subtitle}</h2>
      </div>
      <ul className='Header_series_list'>
        <li className='Header_series_list_item'>
          <Serie num={1} />
        </li>
        <li className='Header_series_list_item'>
          <Serie num={2} />
        </li>
        <li className='Header_series_list_item'>
          <Serie num={3} />
        </li>
        <li className='Header_series_list_item'>
          <Serie num={4} />
        </li>
      </ul>
    </div>
  )
}

export default Header