import React from 'react'
import { getBackgroundHexColor } from '../../utils/background'
import './Quote.css'

const Quote = ({ text, author = '', pr }) => {
  const background = getBackgroundHexColor(pr)
  return text && (
    <div className='Quote_container' style={{ background }}>
      <div className='Quote'>
        <p className='Quote__text'>“{text}”</p>
        <p className='Quote__author'>{author}</p>
      </div>
    </div>
  )
}

export default Quote