import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import SerieList from '../SerieList/SerieList'
import Footer from '../Footer/Footer'
import './ProjectsDetails.css'
import WorksCarousel from '../Carousel/Carousel' 

const ProjectDetails = ({ pr, collection_details, projectName }) => {
  const { work_id } = useParams()
  const [name, setName] = useState('')
  const [project, setProject] = useState('')
  const location = useLocation()
  console.log('useParams', useParams())
  console.log('location.pathname', location.pathname)
  useEffect(() => {
    const path = location.pathname
    const paths = path.split('/')
    const project = paths[paths.length-2]
    setName(work_id)
    setProject(project)
  }, [location.pathname])


  return (
    <>
      <div className='Project_details_project'>
        <div className='Project_details_project_main'>
          <section className='Project_details_project_works_container'>
            <div className='Project_details_main'>
              <div className='Project_details_main_description'>
                <h4 className='Project_details_main_description_project'>{projectName}</h4>
                <h4 className='Project_details_main_description_title'>{collection_details[name]?.title}</h4>
                <h4 className='Project_details_main_description_materials'>Materiales:</h4>
                <p className='Project_details_main_description_material_details'>{collection_details[name]?.material}</p>
                <h4 className='Project_details_main_description_size'>{collection_details[name]?.size}</h4>
                {/* <p className='Project_details_main_description_info'>{collection_details[name]?.title}</p> */}
              </div>
              <div>
                <div className='Project_details_main_img_container'>
                  <img className='Project_details_works_img' src={collection_details[name]?.image_1} alt=''/>
                  <div className='Project_details_showcase' />
                </div>
              </div>
            </div>
            <WorksCarousel collection_details={collection_details} projectKey={project} workName={name} />
          </section> 
        </div>
      <section className='Project_details_serie_list'>
        <SerieList project={pr}/>
      </section>
      <Footer project={pr} light />
      </div>
    </>
  )
}

export default ProjectDetails



/* 
  <li class="product-detail-images__image-wrapper">
    <button class="product-detail-images__image-action-wrapper">
      <div class="media product-detail-images__image">
        <div class="media__wrapper media__wrapper--fill media__wrapper--force-height" style="height: 100%;">
          <picture class="media-image"><source sizes="40vw" media="(min-width: 768px)" srcset="https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/563/2312136800_6_4_1.jpg?ts=1643736555793 563w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/750/2312136800_6_4_1.jpg?ts=1643736555793 750w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/850/2312136800_6_4_1.jpg?ts=1643736555793 850w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/1024/2312136800_6_4_1.jpg?ts=1643736555793 1024w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/1126/2312136800_6_4_1.jpg?ts=1643736555793 1126w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/1500/2312136800_6_4_1.jpg?ts=1643736555793 1500w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/1700/2312136800_6_4_1.jpg?ts=1643736555793 1700w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/2048/2312136800_6_4_1.jpg?ts=1643736555793 2048w">
            <source sizes="100vw" media="" srcset="https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/375/2312136800_6_4_1.jpg?ts=1643736555793 375w, https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/750/2312136800_6_4_1.jpg?ts=1643736555793 750w">
            <img class="media-image__image media__wrapper--media" alt="Imagen 8 de ABRIGO CRUZADO de Zara" src="https://static.zara.net/photos///2022/V/0/1/p/2312/136/800/2/w/860/2312136800_6_4_1.jpg?ts=1643736555793">
          </picture>
          <img src="https://static.zara.net/photos//mkt/misc/watermarks/joinlife-ss19-v2/joinlife-large.svg?ts=1587055628000" class="media-image__watermark" alt="">
        </div>
      </div>
    </button>
  </li>
 */