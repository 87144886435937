import React from 'react'
import config from '../../../config.json'
import es from '../../../I18n/es.json'
import ProjectsDetails from '../../../components/ProjectsDetails/ProjectsDetails'
import Layout from '../../../layout/Layout'

const ProjectDetails = () => {

  const alzando_el_vuelo = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/alzando_el_vuelo.jpg`
  const miedos_enterrados = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/miedos_enterrados.jpg`
  const resurgir = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/resurgir.jpg`
  const encarnar = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/encarnar.jpg`
  const life_purpose = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/life_purpose.jpg`
  const soplo = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/soplo.jpg`
  const encluecar = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/encluecar.jpg`

  const projectName= es.project_hypnopompic_hallucination

  const collection_details = {
    alzando_el_vuelo: { 
      image_1: alzando_el_vuelo,
      title: es.project_hypnopompic_hallucination_1_title,
      material: es.project_hypnopompic_hallucination_1_material,
      size: es.project_hypnopompic_hallucination_1_size,
    },
    miedos_enterrados: { 
      image_1: miedos_enterrados,
      title: es.project_hypnopompic_hallucination_2_title,
      material: es.project_hypnopompic_hallucination_2_material,
      size: es.project_hypnopompic_hallucination_2_size,
    },
    resurgir: { 
      image_1: resurgir,
      title: es.project_hypnopompic_hallucination_3_title,
      material: es.project_hypnopompic_hallucination_3_material,
      size: es.project_hypnopompic_hallucination_3_size,
    },
    encarnar: { 
      image_1: encarnar,
      title: es.project_hypnopompic_hallucination_4_title,
      material: es.project_hypnopompic_hallucination_4_material,
      size: es.project_hypnopompic_hallucination_4_size,
    },
    life_purpose: { 
      image_1: life_purpose,
      title: es.project_hypnopompic_hallucination_5_title,
      material: es.project_hypnopompic_hallucination_5_material,
      size: es.project_hypnopompic_hallucination_5_size,
    },
    soplo: { 
      image_1: soplo,
      title: es.project_hypnopompic_hallucination_6_title,
      material: es.project_hypnopompic_hallucination_6_material,
      size: es.project_hypnopompic_hallucination_6_size,
    },
    encluecar: { 
      image_1: encluecar,
      title: es.project_hypnopompic_hallucination_7_title,
      material: es.project_hypnopompic_hallucination_7_material,
      size: es.project_hypnopompic_hallucination_7_size,
    },
  }

  return (
    <Layout>
      <ProjectsDetails pr={1} collection_details={collection_details} projectName={projectName} />
    </Layout>
  )
}

export default ProjectDetails