import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useStoreDispatch, useStoreState } from '../../context/StateContext'
import es from '../../I18n/es.json';
import './Navbar.css';

const Navbar = () => {
  const [isScrollDown, setIsScrollDown] = useState(false)

  useEffect(() => {
    const limit = 50
    const scroll = () => setIsScrollDown(window.scrollY >= limit)
    window.addEventListener('scroll', scroll)
    return () => window.removeEventListener('scroll', scroll)
  }, [])

  const isOpen = useStoreState(state => state.isOpenSideDrawer)
  const { openSideDrawer, closeSideDrawer } = useStoreDispatch()
  const handleSidedrawer = () => {
    isOpen ? closeSideDrawer() : openSideDrawer()
  }
  
  const backgound = isScrollDown ? 'backgound_active' : 'backgound_disable'
  
  return (
    <div className={`Navbar ${backgound}`}>
     <div className='Navbar_left'>
        <Link to='/'>
          <div className='Navbar_artist_artist_logo_container'>
            <p className='Navbar_artist_name'>
              Ardea
            </p>
            <p className='Navbar_artist_name'>
              Cinerea
            </p>
          </div>
        </Link>
      </div>
      <div className='Navbar_right'>
        <ul className='Navbar_right_ul'>
          <li className='Navbar_right_ul_li Navbar_desktop' >
            <Link to='/'>
              <span>{es.navbar_home}</span>
            </Link>
          </li>
          <li className='Navbar_right_ul_li Navbar_desktop'>
            <Link to='/studio'>
              <span>{es.navbar_studio}</span>
            </Link>
          </li>
          <li className='Navbar_right_ul_li Navbar_desktop'>
            <Link to='/exhibitions'>
              <span>{es.navbar_exhibitions}</span>
            </Link>
          </li>
          <li className='Navbar_right_ul_li Navbar_desktop'>
            <Link to='/about'>
              <span>{es.navbar_about}</span>
            </Link>
          </li>
          <li className='Navbar_right_ul_li Navbar_mobile' onClick={handleSidedrawer}>
            <span>{es.navbar_menu}</span>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Navbar