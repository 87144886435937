import React from 'react'
import { Link } from 'react-router-dom'
import eye_icon from '../../images/ver.png'
import './ArtWorkItem.css'

const ArtWorkItem = ({ link, src, title }) => {
  return (
    <div className='Art_work_item_container'>
      <div className='Art_work_item'>
        <Link to={link}>
          <section className='Art_work_item_link'>
            <div className='Art_work_item_img_container'>
              <img className='Art_work_item_img' src={src} alt=''/>
              <div className='Art_work_item_showcase' />
            </div>
            <div className='Art_work_item_title_container'>
              <div className='Art_work_item_title_area'>
                <img className='Art_work_item_view_more' src={eye_icon} height='20' width='20' alt='View more icon'/>
                <span className='Art_work_item_title'>{title}</span>
              </div>
            </div>
          </section>
        </Link>
      </div>
    </div>
  )
}

export default ArtWorkItem