export const ReducerActions = {
  OPEN_SIDEDRAWER: 'OPEN_SIDEDRAWER',
  CLOSE_SIDEDRAWER: 'CLOSE_SIDEDRAWER'
}

export const reducer = (state, action)=> {
  switch(action.type) {
    case ReducerActions.OPEN_SIDEDRAWER: return ({
      ...state,
      isOpenSideDrawer: true
    })
    case ReducerActions.CLOSE_SIDEDRAWER: return ({
      ...state,
      isOpenSideDrawer: false
    })
    default: return state
  }
}