import Project from '../../../components/Project/Project'
import ArtWorkItem from '../../../components/ArtWorkItem/ArtWorkItem'
import Layout from '../../../layout/Layout'
import { journey } from '../../../resources/artWork'

const Home = () => {
  const { images } = journey
  return (
    <Layout>
      <div className='Projects'>
        <Project pr={3} {...journey}>
          {images.map(item => <ArtWorkItem {...item} />)}
        </Project>
      </div>
    </Layout>
  )
}

export default Home;