import React from 'react'
import Header from '../../components/Header/Header'
import Layout from '../../layout/Layout'
import Footer from '../../components/Footer/Footer'
import './Home.css'

const Home = () => {

  return (
    <Layout>
      <div className='Home'>
        <Header />
        <Footer light />
      </div>
    </Layout>
  )
}

export default Home
