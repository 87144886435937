import React from 'react';
import es from '../../I18n/es.json'
import Serie from '../Serie/Serie'
import './SerieList.css'

const SerieList = ({ project, light }) => {
  return (
    <div className={`Serie_list_related_container ${light ? 'light' : ''}`}>
      <p className='Serie_list_related_title'>{es.see_more}</p>
      <ul className='Serie_list_related_ul'>
        {project !== 1 && (
          <li className='Serie_list_related_ul_li'>
            <Serie num={1} />
          </li>
        )}
        {project !== 4 && (
          <li className='Serie_list_related_ul_li'>
            <Serie num={2} />
          </li>
        )}
        {project !== 3 && (
          <li className='Serie_list_related_ul_li'>
            <Serie num={3} />
          </li>
        )}
        {project !== 4 && (
          <li className='Serie_list_related_ul_li'>
            <Serie num={4} />
          </li>
        )}
      </ul>
    </div>
  )
}

export default SerieList;