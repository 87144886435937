import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react'
import { reducer, ReducerActions } from './StateReducer'

const initialContext = {
  isOpenSideDrawer: false
}

const StoreContext = createContext(initialContext)
const DispatchContext = createContext(()=> {})

export const StateContext = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialContext)
  return (
    <StoreContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StoreContext.Provider>
  )
}

export const useStoreState = (handler) => {
  const state = useContext(StoreContext)
  return handler(state)
}

export const useStoreDispatch = () => {
  
  const dispatch = useContext(DispatchContext)

  const openSideDrawer = useCallback(()=> {
    dispatch({ type: ReducerActions.OPEN_SIDEDRAWER })
  }, [dispatch])

  const closeSideDrawer = useCallback(()=> {
    dispatch({ type: ReducerActions.CLOSE_SIDEDRAWER })
  }, [dispatch])


  return useMemo(()=> ({
    openSideDrawer,
    closeSideDrawer
  }), [dispatch])

}