import React from 'react'
import './MoveButton.css'

const MoveButton = ({text = 'button', onClick, children, width=40, height=40, animation=1}) => {

  return (
    <div className={`MoveButton animation-${animation}`} style={{ width, height }} onClick={onClick}>
      <div className='MoveButton-circle'>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <button className='MoveButton-btn'>
        {children ? children : text}
      </button>
    </div>
  )
}

export default MoveButton