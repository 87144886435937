import React, { useEffect, useRef, useState } from 'react'
import es from '../../I18n/es.json'
import './ProgressBar.css'

const ProgressBar = ({ scroll = null, pageWidth, barPageWidth, setScroll, scrollRef }) => {
  const progressBarRef = useRef()
  const [width, setWidth] = useState(0)
  const barWidth = progressBarRef?.current?.offsetWidth
  let sc = scroll.toFixed()
  const total = barPageWidth - pageWidth

  useEffect(() => {
    if (!scroll) return null
    if (sc < pageWidth) setWidth(0)
    else if ((sc - pageWidth) > barPageWidth) setWidth(barWidth)
    else setWidth((barWidth * (sc - pageWidth)) / total)
  }, [scroll])
  const getStyleVisible = () => {
    if (width > 0 && width < barWidth) return 'Progress_bar__visible'
    else return 'Progress_bar__hide'
  } 
  const styleVisible = getStyleVisible()

  const goToStart = () => {
    scrollRef.scrollTo({ left: 0, behavior: 'smooth' })
    setWidth(0)
  }
  const goToEnd = () => {
    scrollRef.scrollTo({ left: barPageWidth + pageWidth, behavior: 'smooth' })
  }

  return (
    <div className={`Progress_bar_container ${styleVisible}`}>
      <span className='Progress_bar-nav' onClick={goToStart}>{es.go_start}</span>
      <div ref={progressBarRef} className='Progress_bar_box'>
          <div className='Progress_bar' style={{ width }}></div>
      </div>
      <span className='Progress_bar-nav' onClick={goToEnd}>{es.go_end}</span>
    </div>
  )
}

export default ProgressBar