import config from '../../config.json'
import es from '../../I18n/es.json'

const apostillar = `${config.common.aws_s3}/art-projects/journey/quality-images/apostillar.jpg`
const conjuro = `${config.common.aws_s3}/art-projects/journey/quality-images/conjuro.jpg`
const escalera_de_caracol = `${config.common.aws_s3}/art-projects/journey/quality-images/escalera_de_caracol.jpg`
const resurrecion_de_la_bestia = `${config.common.aws_s3}/art-projects/journey/quality-images/resurrecion_de_la_bestia.jpg`
const solo_presente = `${config.common.aws_s3}/art-projects/journey/quality-images/solo_presente.jpg`
const sueno_lucido = `${config.common.aws_s3}/art-projects/journey/quality-images/sueno_lucido.jpg`
const puerta_shambhala = `${config.common.aws_s3}/art-projects/journey/quality-images/puerta_shambhala.jpg`
const luz_alma = `${config.common.aws_s3}/art-projects/journey/quality-images/luz_alma.jpg`
const fluir = `${config.common.aws_s3}/art-projects/journey/quality-images/fluir.jpg`
const regreso_al_yo_soy = `${config.common.aws_s3}/art-projects/journey/quality-images/regreso_al_yo_soy.png`

const backgroundImage = `${config.common.aws_s3}/headers/3.jpeg`

export default {
  title: es.journey_to_oneself,
  subtitle: '',
  quoteText: es.project_hypnopompic_hallucination_quote_text,
  quoteAuthor: es.project_hypnopompic_hallucination_quote_author,
  backgroundImage: backgroundImage,
  link: '/projects/journey',
  images:[
    {
      title: es.project_journey_to_oneself_puerta_shambhala,
      link: '/projects/journey/puerta_shambhala',
      src: puerta_shambhala, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_luz_alma,
      link: '/projects/journey/luz_alma',
      src: luz_alma, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_resurrecion_de_la_bestia,
      link: '/projects/journey/resurrecion_de_la_bestia',
      src: resurrecion_de_la_bestia, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_sueno_lucido,
      link: '/projects/journey/sueno_lucido',
      src: sueno_lucido, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_conjuro,
      link: '/projects/journey/conjuro',
      src: conjuro, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_escalera_de_caracol,
      link: '/projects/journey/escalera_de_caracol',
      src: escalera_de_caracol, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_fluir,
      link: '/projects/journey/fluir',
      src: fluir, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_apostillar,
      link: '/projects/journey/apostillar',
      src: apostillar, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_solo_presente,
      link: '/projects/journey/solo_presente',
      src: solo_presente, alt:'serie2'
    },
    {
      title: es.project_journey_to_oneself_regreso_al_yo_soy,
      link: '/projects/journey/regreso_al_yo_soy',
      src: regreso_al_yo_soy, alt:'serie2'
    }
  ]
}