import Footer from '../../components/Footer/Footer'
import Layout from '../../layout/Layout'
import config from '../../config.json'
import './Studio.css'

const Studio = () => {
  const studioVideo = `${config.common.aws_s3}/video/studio_1_1.mp4`
  const studioVideoWebm = `${config.common.aws_s3}/video/studio.webm`
  const poster = `${config.common.aws_s3}/ardea_studio.png`
  return (
    <Layout>
      <div className='Studio'>
        <header className='Studio-header'>
          <video className='Studio-video' poster={poster} webkit-playsinline='true' playsInline autoPlay muted loop id='myVideo'>
            <source src={studioVideoWebm} type='video/webm' />
            <source src={studioVideo} type='video/mp4' />
          </video>
        </header>
        <Footer light />
      </div>
    </Layout>
  )
}

export default Studio