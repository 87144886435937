import hypnopompicResources from './artWorks/hypnopompic'
import yesterday_anotationResources from './artWorks/yesterday_anotation'
import integratedResources from './artWorks/integrated'
import journeyResources from './artWorks/journey'

export const hypnopompic = hypnopompicResources
export const yesterday_anotation = yesterday_anotationResources
export const integrated = integratedResources
export const journey = journeyResources

export const artWorks = [
  hypnopompicResources,
  yesterday_anotationResources,
  integratedResources,
  journeyResources,
]
