import React from 'react'
import config from '../../../config.json'
import es from '../../../I18n/es.json'
import ProjectsDetails from '../../../components/ProjectsDetails/ProjectsDetails'
import Layout from '../../../layout/Layout'

const ProjectDetails = () => {
  const apostillar = `${config.common.aws_s3}/art-projects/journey/quality-images/apostillar.jpg`
  const conjuro = `${config.common.aws_s3}/art-projects/journey/quality-images/conjuro.jpg`
  const escalera_de_caracol = `${config.common.aws_s3}/art-projects/journey/quality-images/escalera_de_caracol.jpg`
  const resurrecion_de_la_bestia = `${config.common.aws_s3}/art-projects/journey/quality-images/resurrecion_de_la_bestia.jpg`
  const solo_presente = `${config.common.aws_s3}/art-projects/journey/quality-images/solo_presente.jpg`
  const sueno_lucido = `${config.common.aws_s3}/art-projects/journey/quality-images/sueno_lucido.jpg`
  const puerta_shambhala = `${config.common.aws_s3}/art-projects/journey/quality-images/puerta_shambhala.jpg`
  const luz_alma = `${config.common.aws_s3}/art-projects/journey/quality-images/luz_alma.jpg`
  const fluir = `${config.common.aws_s3}/art-projects/journey/quality-images/fluir.jpg`
  const regreso_al_yo_soy = `${config.common.aws_s3}/art-projects/journey/quality-images/regreso_al_yo_soy.png`

  const projectName = es.project_journey_to_oneself

  const collection_details = {
    apostillar: { 
      image_1: apostillar,
      title: es.project_journey_1_title,
      material: es.project_journey_1_material,
      size: es.project_journey_1_size,
    },
    conjuro: { 
      image_1: conjuro,
      title: es.project_journey_2_title,
      material: es.project_journey_2_material,
      size: es.project_journey_2_size,
    },
    escalera_de_caracol: { 
      image_1: escalera_de_caracol,
      title: es.project_journey_3_title,
      material: es.project_journey_3_material,
      size: es.project_journey_3_size,
    },
    resurrecion_de_la_bestia: { 
      image_1: resurrecion_de_la_bestia,
      title: es.project_journey_4_title,
      material: es.project_journey_4_material,
      size: es.project_journey_4_size,
    },
    solo_presente: { 
      image_1: solo_presente,
      title: es.project_journey_5_title,
      material: es.project_journey_5_material,
      size: es.project_journey_5_size,
    },
    sueno_lucido: { 
      image_1: sueno_lucido,
      title: es.project_journey_6_title,
      material: es.project_journey_6_material,
      size: es.project_journey_6_size,
    },
    puerta_shambhala: { 
      image_1: puerta_shambhala,
      title: es.project_journey_7_title,
      material: es.project_journey_7_material,
      size: es.project_journey_7_size,
    },
    luz_alma: { 
      image_1: luz_alma,
      title: es.project_journey_8_title,
      material: es.project_journey_8_material,
      size: es.project_journey_8_size,
    },
    fluir: { 
      image_1: fluir,
      title: es.project_journey_9_title,
      material: es.project_journey_9_material,
      size: es.project_journey_9_size,
    },
    regreso_al_yo_soy: { 
      image_1: regreso_al_yo_soy,
      image_2: regreso_al_yo_soy,
      title: es.project_journey_10_title,
      material: es.project_journey_10_material,
      size: es.project_journey_10_size,
    },
  }

  return (
    <Layout>
      <ProjectsDetails 
        pr={1} 
        collection_details={collection_details} 
        projectName={projectName} 
      />
    </Layout>
  )
}

export default ProjectDetails