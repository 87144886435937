import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { StateContext } from './context/StateContext'
import ScrollToTop from './hooks/ScrollToTop'
import FadeIn from './hooks/FadeIn'
import './index.css'

const app = (
  <BrowserRouter>
    <StateContext>
      <ScrollToTop>
        <FadeIn>
          <App />
        </FadeIn>
      </ScrollToTop>
    </StateContext>
  </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'))
