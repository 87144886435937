import React, { useRef } from 'react'
import ProgressBar from '../ProgressBar/ProgressBar'
import Quote from '../Quote/Quote'
import ProjectStory from '../ProjectStory/ProjectStory'
import useSideScroll from '../../hooks/useSideScroll'
import Footer from '../Footer/Footer'
import es from '../../I18n/es.json'
import config from '../../config.json'
import './Project.css'

const Project = ({pr, title, footerColorText, subtitle, quoteAuthor, quoteText, children }) => {
  
  const MIN_WIDTH = 630
  const { elRef: scrollRef, scroll, setScroll } = useSideScroll()
  const workContainerRef = useRef()
  const isDesktop = typeof window === 'undefined' ? false : window.innerWidth > MIN_WIDTH
  const pageWidth = scrollRef?.current?.offsetWidth + 590
  const barPageWidth = workContainerRef?.current?.offsetWidth + 800
  const ref = isDesktop ? scrollRef : null
  const backgroundImage = `${config.common.aws_s3}/headers/${pr}.jpeg`

  const description = `En un elevado monte donde la arboleda cubría el cielo, había un escuálido árbol que se enraizaba a duras penas entre troncos robustos de copas exuberantes.
  El cielo regaba el manto verde y el sol iluminaba la arboleda, pero el raquítico árbol cada día más esmirriado estaba. Tronco macilento. Envoltura de nada ¿Qué podía hacer si la savia no lograba amamantar sus ramas? Pobre temeroso, Había olvidado algo importante. Varias aves volaban desde su  futuro para invocar sus recuerdos y velar su despertar.`

  return (
    <div ref={ref} className='Project'>
      <div className='Project_main'>
        <section className='Project_header img_bg_3 background'>
          <p className='Project_header_title'>{title}</p>
          {/* <p className='Project_header_subtitle'>{subtitle}</p> */}
          <div className='Project_header_scroll_indicator'>
            <div className='Project_header_scroll_indicator__line'>
            </div>
            <p className='Project_header_scroll_indicator__label'>{es.project_scroll_call_to_action}</p>
          </div>
          <div className='Project_header_mask' style={{ backgroundImage: `url('${backgroundImage}')`}} />
        </section>
        <section>
          <Quote pr={pr} text={quoteText} author={quoteAuthor} />
        </section>
        <section>
          <ProjectStory title={title} text={description} author={quoteAuthor} />
        </section>
        <section className='Project_works_container'>
          <section ref={workContainerRef} className='Project_works'>
            {children}
            {isDesktop && <ProgressBar scrollRef={scrollRef.current} setScroll={setScroll} pageWidth={pageWidth} barPageWidth={barPageWidth} scroll={scroll} />}
          </section>
        </section> 
        <Footer project={pr} showSeries row footerColorText={footerColorText} />
      </div>
    </div>
  )
}

export default Project