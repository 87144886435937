import { useNavigate } from 'react-router-dom'
import es from '../../I18n/es.json'
import DefaultButton from '../Buttons/DefaultButton/DefaultButton'
import { artWorks } from '../../resources/artWork'
import SerieArtWork from './SerieArtWork'
import './Serie.css'

const Serie = ({ num, light }) => {
  const navigate = useNavigate()

  const serie = artWorks[num-1]
  const { link, title, images } = serie
  const style = light ? 'light' : ''

  const handleNavigation = () => link && navigate(link)

  return (
    <div className={`Serie ${style}`} onClick={handleNavigation} >
      <SerieArtWork num={num} images={images} />
      <div className='Serie_details_container'>
        <p className='Serie_details_title'>{title}</p>
        <DefaultButton 
          className='Serie_details_button' 
          text={es.call_to_action} 
        />
      </div>
    </div>
  )
}

export default Serie;