import React, { useState } from 'react'
import es from '../../I18n/es.json'
import { sendForm } from '../../utils/api/contact'
import DefaultButton from '../Buttons/DefaultButton/DefaultButton'
import './Form.css'

export const validateEmail = email=> {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const validateForm = ({ email, gdpr, name, message }) => {
  let reasons = {}
  if (!validateEmail(email)) reasons.email = 'Email no válido'
  if (!name) reasons.name = 'Nombre no válido'
  if (!message) reasons.message = 'Mensaje no válido'
  if (!gdpr) reasons.gdpr = 'Por favor, se requiere marcar la casilla'
  return { reasons, hasErrors: Object.keys(reasons).length > 0 }
}

const Form = () => {
  const emptyForm = { email: '', gdpr: false, message: '', name: '' }

  const [formData, setFormData] = useState(emptyForm)
  const [errorData, setErrorData] = useState({})
  const [sendStatus, setSendStatus] = useState({ submitting: false, status: false })

  const handleChange = (evt, key)=> {
    const value = evt.target.value
    setFormData(current => ({ ...current, [key]: value }))
  }

  const handleGdprChange = () => {
    setFormData(current => ({ ...current, gdpr: !current.gdpr }))
  }

  const handleSubmit = evt => {
    evt.preventDefault()
    const errors = validateForm(formData)
    if (errors.hasErrors) {
      setErrorData(errors.reasons)
      return
    } 
        setSendStatus({ submitting: true, status: true })
    // sendForm(formData)
    //   .then(()=> {
    //     setFormData(emptyForm)
    //     setSendStatus({ submitting: true, status: true })
    //   })
    //   .catch(() => {
    //     setSendStatus({ submitting: true, status: false })
    //   })
  }

  return ( 
    <div className='Form-container'>
      {sendStatus.submitting && sendStatus.status ? (
        <div className='Form_contact_succeed_container'>
          <span className='Form_contact_succeed_message'>{es.form_send_successfully}</span>
          {/* <span className='Form_contact_succeed_message'>{es.form_send_successfully_2}</span> */}
        </div>
      ) : (
        <form id='contact-newsletter' autoComplete="false" className='Form_contact_container'>
          <div className='Form_contact_name_inputs'>
            <div className='Form_contact_input_container'>
              <label className='Form_contact_input_label' htmlFor='name'>{es.input_name_email}</label>
              <input
                id='name'
                placeholder={es.input_name_email}
                className='Form_contact_input'
                type="name"
                value={formData.name}
                onChange={(evt) => handleChange(evt, 'name')}
              />
              <span className='Form_contact_error_message' >{errorData.name}</span>
            </div>
            <div className='Form_contact_input_container'>
              <label className='Form_contact_input_label' htmlFor='email' name=''>Email</label>
              <input
                id='email'
                placeholder="Email"
                className='Form_contact_input'
                type="email"
                value={formData.email}
                onChange={(evt) => handleChange(evt, 'email')}
              />
              <span className='Form_contact_error_message' >{errorData.email}</span>
            </div>
          </div>

          <div className='Form_contact_input_container'>
            <label className='Form_contact_input_label' htmlFor='textarea' name=''>Mensaje</label>
            <textarea
              id='textarea'
              placeholder={es.contact_textarea_label}
              className='Form_contact_input_textarea'
              value={formData.message}
              onChange={(evt) => handleChange(evt, 'message')}
            />
            <span className='Form_contact_error_message' >{errorData.message}</span>
          </div>
          <div >
            <div className='Form_contact_terms_container'>
              <div className='Form_contact_terms_button_container'>
                <input
                  id="checkbox"
                  type="checkbox"
                  className={`Form_contact_terms_input ${formData.gdpr ? 'checked' : ''}`}
                  value={formData.gdpr}
                  onChange={handleGdprChange}
                />
                <div className='Form_contact_terms_custom_checkbox' />
                <div className="state">
                  <label htmlFor='checkbox'>
                    <span className='Form_contact_terms'>
                      <span className='Form_contact_terms_accept' onClick={handleGdprChange}>Acepto</span> las <a href='/' className='is_link'> condiciones</a> y
                      <a href='/' className='is_link'> política de privacidad</a>
                    </span>
                  </label>
                </div>
              </div>
              <span className='Form_contact_error_message'>{errorData.gdpr}</span>
          </div>
            <DefaultButton text='Enviar' onClick={handleSubmit}/>
          </div>
        </form>
      )}
    </div>
  )
}

export default Form;