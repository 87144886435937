import React from 'react'
import { useStoreState, useStoreDispatch } from '../context/StateContext'
import SideDrawer from '../components/SideDrawer/SideDrawer'
import Navbar from '../components/Navbar/Navbar'
import Cookies from '../components/Cookies/Cookies'
import './Layout.css';

const Layout = ({ children }) => {
  const isOpen = useStoreState(state => state.isOpenSideDrawer)
  const { closeSideDrawer } = useStoreDispatch()

  return (
    <>
      <div className='Layout'>
        <Navbar />
        {children}
        <SideDrawer />
        {isOpen && <div className='Layout_background' onClick={closeSideDrawer} />} 
        <Cookies />
      </div>
    </>
  )
}

export default Layout;