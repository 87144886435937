import React from 'react'
import config from '../../../config.json'
import es from '../../../I18n/es.json'
import ProjectsDetails from '../../../components/ProjectsDetails/ProjectsDetails'
import Layout from '../../../layout/Layout'

const ProjectDetails = () => {
  const como_es_es_perfecto = `${config.common.aws_s3}/art-projects/integrated/quality-images/como_es_es_perfecto.jpg`
  const el_abrazo_de_la_lechuza = `${config.common.aws_s3}/art-projects/integrated/quality-images/el_abrazo_de_la_lechuza.jpg`
  const mirar_viendo = `${config.common.aws_s3}/art-projects/integrated/quality-images/mirar_viendo.jpg`
  const pulsion_en_la_tierra = `${config.common.aws_s3}/art-projects/integrated/quality-images/pulsion_en_la_tierra.jpg`
  const sonido = `${config.common.aws_s3}/art-projects/integrated/quality-images/sonido.jpg`

  const projectName = es.project_integrated

  const collection_details = {
    como_es_es_perfecto: { 
      image_1: como_es_es_perfecto,
      title: es.project_integrated_1_title,
      material: es.project_integrated_1_material,
      size: es.project_integrated_1_size,
      
    },
    el_abrazo_de_la_lechuza: { 
      image_1: el_abrazo_de_la_lechuza,
      title: es.project_integrated_2_title,
      material: es.project_integrated_2_material,
      size: es.project_integrated_2_size,
      
    },
    mirar_viendo: { 
      image_1: mirar_viendo,
      title: es.project_integrated_3_title,
      material: es.project_integrated_3_material,
      size: es.project_integrated_3_size,
      
    },
    pulsion_en_la_tierra: { 
      image_1: pulsion_en_la_tierra,
      title: es.project_integrated_4_title,
      material: es.project_integrated_4_material,
      size: es.project_integrated_4_size,
      
    },
    sonido: { 
      image_1: sonido,
      title: es.project_integrated_5_title,
      material: es.project_integrated_5_material,
      size: es.project_integrated_5_size,
    }
  }

  return (
    <Layout>
      <ProjectsDetails pr={1} collection_details={collection_details} projectName={projectName} />
    </Layout>
  )
}

export default ProjectDetails