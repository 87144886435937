import { useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import DefaultButton from '../../components/Buttons/DefaultButton/DefaultButton'
import es from '../../I18n/es.json'
import Layout from '../../layout/Layout'
import './Page404.css'

const Page404 = () => {
  const navigate = useNavigate()
  return (
    <Layout>
      <div className='Page404'>
        <div className='Page404_main'>
          <div className='Page404_main_area'>
            <div className='Page404_main_block'>
              <p className='Page404_main_title'>{es.error_404}</p>
              <p className='Page404_main_description'>{es.error_404_details}</p>
            </div>
            <div className='Page404_main_block'>
              <DefaultButton text='Volver a Inicio' onClick={() => navigate('/')}/>
            </div>
          </div>
        </div>
        <Footer light />
      </div>
    </Layout>
  )
}

export default Page404