import config from '../../config.json'
import es from '../../I18n/es.json'

const alzando_el_vuelo = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/alzando_el_vuelo.jpg`
const miedos_enterrados = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/miedos_enterrados.jpg`
const resurgir = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/resurgir.jpg`
const encarnar = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/encarnar.jpg`
const life_purpose = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/life_purpose.jpg`
const soplo = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/soplo.jpg`
const encluecar = `${config.common.aws_s3}/art-projects/hypnopompic-hallucination/quality-images/encluecar.jpg`

const backgroundImage = `${config.common.aws_s3}/headers/1.jpeg`

export default {
  title: es.hypnopompic_hallucination,
  subtitle: '',
  quoteText: es.project_hypnopompic_hallucination_quote_text,
  quoteAuthor: es.project_hypnopompic_hallucination_quote_author,
  backgroundImage: backgroundImage,
  link: '/projects/hypnopompic-hallucination',
  images: [
    { 
      title: es.project_hypnopompic_hallucination_fears,
      link: '/projects/hypnopompic-hallucination/miedos_enterrados', 
      src: miedos_enterrados, alt:'serie2'
    },
    { 
      title: es.project_hypnopompic_hallucination_resurge,
      link: '/projects/hypnopompic-hallucination/resurgir', 
      src: resurgir, alt:'serie2'
    },
    { 
      title: es.project_hypnopompic_hallucination_flight,
      link: '/projects/hypnopompic-hallucination/alzando_el_vuelo', 
      src: alzando_el_vuelo, alt:'serie2'
    },
    { 
      title: es.project_hypnopompic_hallucination_hook,
      link: '/projects/hypnopompic-hallucination/encluecar', 
      src: encluecar, alt:'serie2'
    },
    { 
      title: es.project_hypnopompic_hallucination_blew,
      link: '/projects/hypnopompic-hallucination/soplo', 
      src: soplo, alt:'serie2'
    },
    { 
      title: es.project_hypnopompic_hallucination_purpose,
      link: '/projects/hypnopompic-hallucination/life_purpose', 
      src: life_purpose, alt:'serie2'
    },
    { 
      title: es.project_hypnopompic_hallucination_embody,
      link: '/projects/hypnopompic-hallucination/encarnar', 
      src: encarnar, alt:'serie2'
    }
  ],
}