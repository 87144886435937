import React, { useState, useEffect } from 'react'
import es from '../../I18n/es.json'
import Form from '../Form/Form'
import SocialNetworks from '../SocialNetworks/SocialNetworks'
import { getBackgroundHexColor } from '../../utils/background'
import FooterNav from '../FooterNav/FooterNav'
import './Footer.css'

const Footer = ({ project, showSeries=false, row, light }) => {
  const [year, setYear] = useState('')

  useEffect(() => {
    setYear(new Date().getFullYear())
  }, [])

  const background = getBackgroundHexColor(light ? 0 : project)
  const series = showSeries ? 'show_series' : 'hide_series'
  const rowClass = row ? 'Footer_row' : ''

  return (
    <div id='footer' className={`Footer ${series} ${rowClass}`} style={{background}}>
      <div className='Footer_main'>
        <div className='Footer_contact'>
          <div className='Footer_contact_info'>
            <p className='Footer_contact_info-title'>{es.contact_title}</p>
            <p className='Footer_contact_info-description'>{es.contact_info}</p>
            <div className='Footer_rrss_container'>
              <SocialNetworks />
            </div>
          </div>
          <Form />
        </div>
        {light && <FooterNav />}
        <div className='Footer_info_container'>
          <span>©{year} Ardea Cinerea. <br/> {es.rights_reserved}</span>
        </div>
      </div>
    </div>
  )
}

export default Footer;