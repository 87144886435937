import React from 'react'
import './UnderscoreAnimation.css'


const UnderscoreAnimation = ({ isActive }) => {
  return (
    <div className={`sidedrawer-button ${isActive ? 'active' : ''}`}>
      <div className='first-line'></div>
    </div>
  )
}

export default UnderscoreAnimation