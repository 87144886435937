import React, { useState, useEffect } from 'react'
import './Serie.css'

const SerieArtWork = ({ images, num }) => {
  const [active, setActive] = useState(1)

  useEffect(() => {
    imageTimeOut(num, setActive)
  }, [active])

  return (
    images.length > 0 && (
      <div className='Serie_art_container'>
        {renderImages(num, active, images)}
        <div className='Serie_showcase' />
      </div>
    )
  )
}

const imageTimeOut = (num, setActive) => {
  console.log('num', num)
  if (num !== 1) return
  const time = 3300 + (3000 * num)
  const invokeTimer = () => {
    setTimeout(()=> {
      setActive(active === images.length ? 1 : active + 1)
    }, time)
  }
  invokeTimer()
  return () => clearTimeout(invokeTimer())
}

const renderImages = (num, active, images) => {
  const isFirstProject = num === 1
  if (isFirstProject) {
    const getVisibleStyle = idx => active === idx+1 ? 'visible' : 'hide'
    return images.map(({ src, alt }, idx)=>(
      <img 
        key={src} 
        className={`Serie_details_img ${getVisibleStyle(idx)}`} 
        src={src} alt={alt} 
      />
    ))
  } else {
    const { src, alt } = images[0]
    return <img className='Serie_details_img' src={src} alt={alt} />
  }
}

export default SerieArtWork