import React from 'react'
import config from '../../../config.json'
import es from '../../../I18n/es.json'
import ProjectsDetails from '../../../components/ProjectsDetails/ProjectsDetails'
import Layout from '../../../layout/Layout'

const ProjectDetails = () => {
  const ancestrina = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_high.jpg`
  const ancestrina_I = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_I_high.jpg`
  const ancestrina_II = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_II_high.jpg`
  const ancestrina_III = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_III_high.jpg`
  const ancestrina_IV = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_IV_high.jpg`
  const ancestrina_V = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_V_high.jpg`
  const projectName = es.project_yesterday_anotation

  const collection_details = {
    ancestrina: { 
      image_1: ancestrina,
      title: es.project_yesterday_anotation_1_title,
      material: es.project_yesterday_anotation_1_material,
      size: es.project_yesterday_anotation_1_size,
    },
    ancestrina_I: { 
      image_1: ancestrina_I,
      title: es.project_yesterday_anotation_2_title,
      material: es.project_yesterday_anotation_2_material,
      size: es.project_yesterday_anotation_2_size,
    },
    ancestrina_II: { 
      image_1: ancestrina_II,
      title: es.project_yesterday_anotation_3_title,
      material: es.project_yesterday_anotation_3_material,
      size: es.project_yesterday_anotation_3_size,
    },
    ancestrina_III: { 
      image_1: ancestrina_III,
      title: es.project_yesterday_anotation_4_title,
      material: es.project_yesterday_anotation_4_material,
      size: es.project_yesterday_anotation_4_size,
    },
    ancestrina_IV: { 
      image_1: ancestrina_IV,
      title: es.project_yesterday_anotation_5_title,
      material: es.project_yesterday_anotation_5_material,
      size: es.project_yesterday_anotation_5_size,
    },
    ancestrina_V: { 
      image_1: ancestrina_V,
      title: es.project_yesterday_anotation_6_title,
      material: es.project_yesterday_anotation_6_material,
      size: es.project_yesterday_anotation_6_size,
    }
  }

  return (
    <Layout>
      <ProjectsDetails pr={4} collection_details={collection_details} projectName={projectName} />
    </Layout>
  )
}

export default ProjectDetails