import React from 'react'
import { getBackgroundHexColor } from '../../utils/background'
import './ProjectStory.css'

const ProjectStory = ({ text, title, author, pr }) => {
  return null
  return text && (
    <div className='ProjectStory_container'>
      <div className='ProjectStory'>
        <p className='ProjectStory__title'>{title}</p>
        <p className='ProjectStory__text'>{text}</p>
      </div>
    </div>
  )
}

export default ProjectStory