import { Routes, Route, Navigate } from 'react-router-dom'

import Home from './pages/Home/Home'
import About from './pages/About/About'
import Exhibitions from './pages/Exhibitions/Exhibitions'
import Studio from './pages/Studio/Studio'

import HypnopompicHallucination from './pages/projects/hypnopompic-hallucination'
import HypnopompicHallucinationDetails from './pages/projects/hypnopompic-hallucination/Details'

import Integrated from './pages/projects/integrated'
import IntegratedDetails from './pages/projects/integrated/Details'

import Journey from './pages/projects/journey'
import JourneyDetails from './pages/projects/journey/Details'

import YesterdayAnotation from './pages/projects/yesterday-anotation'
import YesterdayAnotationDetails from './pages/projects/yesterday-anotation/Details'

import Page404 from './pages/Page404/Page404'

import './App.css';

const App = () => {

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/exhibitions' element={<Exhibitions />} />
      <Route path='/studio' element={<Studio />} />
      {/* <Route path='/Media' element={<Media />} /> */}

      <Route path='/projects/hypnopompic-hallucination' element={<HypnopompicHallucination />} />
      <Route path='/projects/hypnopompic-hallucination/:work_id' element={<HypnopompicHallucinationDetails />} />

      <Route path='/projects/integrated' element={<Integrated />} />
      <Route path='/projects/integrated/:work_id' element={<IntegratedDetails />} />

      <Route path='/projects/journey' element={<Journey />} />
      <Route path='/projects/journey/:work_id' element={<JourneyDetails />} />

      <Route path='/projects/yesterday-anotation' element={<YesterdayAnotation />} />
      <Route path='/projects/yesterday-anotation/:work_id' element={<YesterdayAnotationDetails />} />

      <Route path='/404' element={<Page404 />} />

      <Route path='*' element={<Navigate to='/404' />}/>
    </Routes>
  )
}

export default App
