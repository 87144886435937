import React, { useState, useEffect } from 'react'
import es from '../../I18n/es.json'
import './Cookies.css'

const setCookie = value=> {
  let expires = ''
  let date = new Date()
  date.setTime(date.getTime() + (180*24*60*60*1000))
  expires = '; expires=' + date.toUTCString()
  document.cookie = 'cookie_ardea_cinerea=' + JSON.stringify(value)  + expires + '; path=/'
}

const getCookie = ()=> {
  let nameEQ = 'cookie_ardea_cinerea='
  let ca = document.cookie.split(';')
  for (let i=0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0)===' ') c = c.substring(1,c.length)
      if (c.indexOf(nameEQ) === 0) return JSON.parse(c.substring(nameEQ.length, c.length))
  }
  return null
}

const Cookies = () => {
  const [visible, setVisible] = useState(false)

  useEffect(()=> {
    const cookie = getCookie()
    if (cookie) setVisible(false)
    else setVisible(true)
  }, [])

  const handleCookie = () => {
    setVisible(false)
    setCookie('accepted')
  }

  return (
    <div className={`Cookies_container ${visible ? 'visible' : 'hide'}`} >
      <div className='Cookies_main'>
        <p className='Cookies_text'>{es.cookies}</p>
        <button className='Cookies_close' onClick={handleCookie}>{es.cookies_close}</button>
      </div>
    </div>
  )
}

export default Cookies