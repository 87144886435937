import React from 'react'
import { useStoreDispatch, useStoreState } from '../../context/StateContext'
import { Link } from 'react-router-dom'
import es from '../../I18n/es.json'
import huevo from './huevo.png'
import './SideDrawer.css'

const SideDrawer = () => {
  const { closeSideDrawer } = useStoreDispatch()
  const isOpen = useStoreState(state => state.isOpenSideDrawer)
  const handleClose = () => {
    closeSideDrawer()
  }
  return (
    <div className={`SideDrawer ${isOpen ? 'open' : 'close'}`}>
      <div className='SideDrawer_main'>
        <div className='SideDrawer_close_container' onClick={handleClose}>
          <div className='SideDrawer_close'>X</div>
        </div>
        <ul className='SideDrawer_body'>
          <li className='SideDrawer_series_list_item'>
            <Link to='/' onClick={closeSideDrawer}>
              <p className='SideDrawer_body_serie'>{es.navbar_home}</p>
            </Link>
            <ul className='SideDrawer_series_list'>
              <li className='SideDrawer_series_list_project_item'>
                <Link to='/projects/hypnopompic-hallucination' onClick={closeSideDrawer}>
                  {es.project_hypnopompic_hallucination} 
                </Link>
              </li>
              <li className='SideDrawer_series_list_project_item'>
                <Link to='/projects/journey' onClick={closeSideDrawer}>
                  {es.project_journey_to_oneself}
                </Link>
              </li>
              <li className='SideDrawer_series_list_project_item'>
                <Link to='/projects/integrated' onClick={closeSideDrawer}>
                  {es.project_integrated} 
                </Link>
              </li>
              <li className='SideDrawer_series_list_project_item'>
                <Link to='/projects/yesterday-anotation' onClick={closeSideDrawer}>
                  {es.project_yesterday_anotation} 
                </Link>
              </li>
            </ul>
          </li>
          <li className='SideDrawer_series_list_item'>
            <Link to='/studio' onClick={closeSideDrawer}>
              <p className='SideDrawer_body_serie'>{es.navbar_studio}</p>
            </Link>
          </li>
          <li className='SideDrawer_series_list_item'>
            <Link to='/exhibitions' onClick={closeSideDrawer}>
              <p className='SideDrawer_body_serie'>{es.navbar_exhibitions}</p>
            </Link>
          </li>
          <li className='SideDrawer_series_list_item'>
            <Link to='/about' onClick={closeSideDrawer}>
              <p className='SideDrawer_body_serie'>{es.navbar_about}</p>
            </Link>
          </li>
        </ul>
      </div>
      <img className='SideDrawer_background-image' src={huevo} alt=''/>
    </div>
  )
}

export default SideDrawer