import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import es from '../../I18n/es.json'
import config from '../../config.json'
import './FooterNav.css'

const logo = `${config.common.aws_s3}${config.links.logo}`

const FooterNav = () => {
  const [isHidden, setIsHidden] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const path = location.pathname.split('/')
    setIsHidden(path.length === 3 && location.pathname.includes('projects'))
  }, [location])

  return (

    <div className={`FooterNav_container ${isHidden ? 'hide' : 'visible'}`} >
      <div className='FooterNav_title_container'>
        <span className='FooterNav_title'>
          Ardea
        </span>
        <span className='FooterNav_title'>
          Cinerea
        </span>
        <img className='FooterNav_logo' src={logo} alt='' />
      </div>

      <div className='FooterNav_nav_container'>
        <ul className='FooterNav_nav'>
          <li className='FooterNav_nav_li'>
            <Link to='/'>{es.navbar_project}</Link>
            <ul className='FooterNav_nav_li_ul'>
              <li className='FooterNav_nav_li_ul_item'>
                <Link to='/projects/hypnopompic-hallucination'>
                  {es.hypnopompic_hallucination} 
                </Link>
              </li>
              <li className='FooterNav_nav_li_ul_item'>
                <Link to='/projects/journey'>
                  {es.journey_to_oneself}
                </Link>
              </li>
              <li className='FooterNav_nav_li_ul_item'>
                <Link to='/projects/integrated'>
                  {es.integrated} 
                </Link>
              </li>
              <li className='FooterNav_nav_li_ul_item'>
                <Link to='/projects/yesterday-anotation'>
                  {es.yesterday_anotation}
                </Link>
              </li>
            </ul>
          </li>
          <li className='FooterNav_nav_li'>
            <Link to='/studio'>{es.navbar_studio}</Link>
          </li>
          <li className='FooterNav_nav_li'>
            <Link to='/exhibitions'>{es.navbar_exhibitions}</Link>
          </li>
          <li className='FooterNav_nav_li'>
            <Link to='/about'>{es.navbar_about}</Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default FooterNav