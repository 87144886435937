import { Link } from 'react-router-dom'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './Carousel.css'

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1700 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 1700, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const WorksCarousel = ({ collection_details, projectKey, workName }) => {
  return (
    <Carousel
      swipeable={false}
      draggable={false}
      responsive={responsive}
      infinite={true}
      autoPlay={true}
      customTransition='transform 1000ms ease-in-out'
      containerClass='Carousel-container'
      centerMode={true}
      showDots={true}
    >
      {Object.keys(collection_details).map(key => (
        workName !== key ? (
          <Link to={`/projects/${projectKey}/${key}`} className='Carousel-item'>
            <img className='Carousel-item-img' src={collection_details[key].image_1} alt=''/>
            <div className='Carousel-item-img-showcase' />
          </Link>
        ) : null
      ))}
    </Carousel>
  )
}

export default WorksCarousel