import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

function FadeIn({ children }) {
  const { pathname } = useLocation()
  
  useEffect(() => {
    const body = document.querySelector('body')
    body.classList.remove('fade-out')
    body.classList.add('fade-in')
    setTimeout(() => {
      body.classList.remove('fade-in')
      body.classList.add('fade-out')
    }, 50)
  }, [pathname])

  return <>{children}</>
}

export default FadeIn