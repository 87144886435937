import { useRef, useEffect, useState} from "react";

const useHorizontalScroll = () => {
  const elRef = useRef();
  const [scroll, setScroll] = useState(0)
  
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      const onWheel = e => {
        const wheelMove = e.deltaY
        const { scrollLeft } = el
        if (wheelMove == 0) return;
        e.preventDefault();
        const left = scrollLeft + wheelMove
        el.scrollTo({ left });
        setScroll(left)
      };
      el.addEventListener("wheel", onWheel);
      return () => el.removeEventListener("wheel", onWheel);
    }
  }, [])
  return { elRef, scroll, setScroll }
}

export default useHorizontalScroll