import config from '../../config.json'
import es from '../../I18n/es.json'

const como_es_es_perfecto = `${config.common.aws_s3}/art-projects/integrated/quality-images/como_es_es_perfecto.jpg`
const el_abrazo_de_la_lechuza = `${config.common.aws_s3}/art-projects/integrated/low-quality-images/abrazo_lechuza_low.jpg`
const mirar_viendo = `${config.common.aws_s3}/art-projects/integrated/quality-images/mirar_viendo.jpg`
const pulsion_en_la_tierra = `${config.common.aws_s3}/art-projects/integrated/quality-images/pulsion_en_la_tierra.jpg`
const sonido = `${config.common.aws_s3}/art-projects/integrated/quality-images/sonido.jpg`

const backgroundImage = `${config.common.aws_s3}/headers/2.jpeg`

export default {
    title: es.integrated,
    subtitle: '',
    quoteText: es.project_integrated_quote_text,
    quoteAuthor: es.project_integrated_quote_author,
    link: '/projects/integrated',
    backgroundImage: backgroundImage,
    images: [
      {
        title: es.integrated_el_abrazo_de_la_lechuza,
        link: '/projects/integrated/el_abrazo_de_la_lechuza',
        src: el_abrazo_de_la_lechuza, alt:'serie2'
      },
      {
        title: es.integrated_como_es_es_perfecto,
        link: '/projects/integrated/como_es_es_perfecto',
        src: como_es_es_perfecto, alt:'serie2'
      },
      {
        title: es.integrated_pulsion_en_la_tierra,
        link: '/projects/integrated/pulsion_en_la_tierra',
        src: pulsion_en_la_tierra, alt:'serie2'
      },
        {
          title: es.integrated_sonido,
          link: '/projects/integrated/sonido',
          src: sonido, alt:'serie2'
        },
      {
        title: es.integrated_mirar_viendo,
        link: '/projects/integrated/mirar_viendo',
        src: mirar_viendo, alt:'serie2'
      }
   ]
}