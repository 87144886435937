import React from 'react'
import Footer from '../../components/Footer/Footer'
import es from '../../I18n/es.json'
import Layout from '../../layout/Layout'
import './About.css'

const About = () => {
  return (
    <Layout>
      <div className='About'>
        <div className='About_main'>
        <div className='About_main_area'>
          <div className='About_main_statement'>
            <h3 className='About_main_title'>{es.statement}</h3>
            <p className='About_main_bio_description'>{es.statement_1}</p>
            <p className='About_main_bio_description'>{es.statement_2}</p>
          </div>
          <div className='About_main_bio'>
            <h3 className='About_main_title'>{es.bio}</h3>
            <p className='About_main_bio_description'>{es.bio_1}</p>
            <p className='About_main_bio_description'>{es.bio_2}</p>
            <p className='About_main_bio_description'>{es.bio_3}</p>
          </div>
          </div>
        </div>
        <Footer light />
      </div>
    </Layout>
  )
}

export default About