import config from '../../config.json'
import es from '../../I18n/es.json'

const ancestrina = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_high.jpg`
const ancestrina_I = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_I_high.jpg`
const ancestrina_II = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_II_low.jpg`
const ancestrina_III = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_III_high.jpg`
const ancestrina_IV = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_IV_high.jpg`
const ancestrina_V = `${config.common.aws_s3}/art-projects/yesterday-anotation/ancestrina_V_high.jpg`

const backgroundImage = `${config.common.aws_s3}/headers/4.jpeg`

export default {
    title: es.yesterday_anotation,
    subtitle: '',
    quoteText: es.project_yesterday_anotation_quote_text,
    quoteAuthor: es.project_yesterday_anotation_quote_author,
    link: '/projects/yesterday-anotation',
    backgroundImage: backgroundImage,
    images: [
      {  
        title: es.project_yesterday_anotation_ancestrina,
        link: '/projects/yesterday-anotation/ancestrina',
        src: ancestrina, alt:'serie2'
      },
      {  
        title: es.project_yesterday_anotation_ancestrina_I,
        link: '/projects/yesterday-anotation/ancestrina_I',
        src: ancestrina_I, alt:'serie2'
      },
      {  
        title: es.project_yesterday_anotation_ancestrina_II,
        link: '/projects/yesterday-anotation/ancestrina_II',
        src: ancestrina_II, alt:'serie2'
      },
      {  
        title: es.project_yesterday_anotation_ancestrina_III,
        link: '/projects/yesterday-anotation/ancestrina_III',
        src: ancestrina_III, alt:'serie2'
      },
      {  
        title: es.project_yesterday_anotation_ancestrina_IV,
        link: '/projects/yesterday-anotation/ancestrina_IV',
        src: ancestrina_IV, alt:'serie2'
      },
      {  
       title: es.project_yesterday_anotation_ancestrina_V,
       link: '/projects/yesterday-anotation/ancestrina_V',
       src: ancestrina_V, alt:'serie2'
      }
   ]
}